.app__footer {
  width: 100%;
 
  bottom: 0;
  pointer-events: none;
  position: fixed;
  display: flex;
}

.app__footer-right {
  display: flex;
  flex-direction: column;
  
}

.app__socials {

}

.copyright {
  padding: 0 2rem 1.25rem 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  pointer-events: auto;
  position: absolute;
  left: 5rem;


  p {
    color: var(--black-color);
  }

  @media screen and (max-width: 900px) {
    display: none;
  }

  @media screen and (min-width: 2000px) {
    left: 8rem;
  }
}