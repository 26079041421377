.app__skills {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__skills-container {
  width: 80%;
  margin-top: 3rem;
  
  display: flex;

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
}

.app__skills-list {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: end;
  margin-right: 5rem;

  @media screen and (max-width: 900px) {
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
    margin-right: 0rem;
  }
}

.app__skills-item {
  flex-direction: column;
  text-align: center;

  margin: 1rem;
  transition: all 0.3s ease-in-out;

  div {
    width: 5.625rem; // 90px
    height: 5.625rem;
    border-radius: 50%;
    background-color: #edf2f8;

    img {
      width: 50%;
      height: 50%;
    }

    &:hover {
      box-shadow: 0 0 1.5rem #fef4f5;
    }

    @media screen and (min-width: 2000px) {
      width: 9.375rem;
      height: 9.375rem;
    }

    @media screen and (max-width: 900px) {
      width: 4.375rem;
      height: 4.375rem;
    }
  }

  p {
    font-weight: 500;
    margin-top: 0.5rem;
  }

  @media screen and (min-width: 2000px) {
    margin: 1rem 2rem;

    p {
      margin-top: 1rem;
    }
  }
}

.app__skills-exp {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: -5rem;
  margin-left: 5rem;

  @media screen and (max-width: 900px) {
    margin-top: 2rem;
  }
}

.app__skills-exp-item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1rem;
}

.app__skills-exp-jobs {
  flex: 1;
  
  .app__skills-exp-job {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;
    // cursor:pointer;

    h4 {
      font-weight: 500;
    }
    
    p {
      font-weight: 400;
      color: var(--gray-color);
      margin-top: .3125rem; 
    }
  }
}

.app__skills-exp-year {
  margin-right: 3rem;

  p {
    font-weight: 800;
    color: var(--secondary-color)
  }

  @media screen and (max-width: 450px) {
    margin-right: 1rem;
  }
}

.skills-tooltip {
  max-width: 18.75rem !important; // 300px
  background-color: var(--white-color) !important;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.1) !important;
  border-radius: .3125rem !important;
  padding: 1rem !important;
  color: var(--gray-color) !important;
  text-align: center !important;
  line-height: 1.5 !important;
  opacity: 1 !important;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem !important;
    max-width: 83.3rem !important; // 500px
    line-height: 2 !important;
  }
}