.app__header {
  flex: 1;
  width: 100%;
  flex-direction: column;
  z-index: 1;
}

.hero-static {
  font-size: 7rem;
  font-weight: 800;
  text-align: center;
  color: var(--black-color);
  text-transform: capitalize;

  span {
    color: var(--secondary-color);

    @media screen and (max-width: 600px) {
      font-size: 5rem;
    }
  
    @media screen and (max-width: 460px) {
      font-size: 3.8rem;
    }
  }

}

.app__header-content {
  text-align: center;
  z-index: 5;

  h2 {
    text-align: center;
    font-size: 4.6875rem;
    font-weight: 700;
    margin: 0;
    line-height: 1;

    @media screen and (max-width: 600px) {
      font-size: 2rem;
    }

    @media screen and (max-width: 460px) {
      font-size: 1.5rem;
    }
  }
}

.app__header-loop {
  color: var(--secondary-color);
  font-size: 2.1875rem;
  margin-top: .6325rem;
  font-weight: 700;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.125rem;
}

.app__arrow-down {
  position: absolute;
  bottom: 3.75rem;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  color: var(--secondary-color);
}