.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2.5rem;
  margin-top: 2rem;
}

.app__profile-item {
  width: 11.875rem; // 190px
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    height: 10.625rem; // 170px
    border-radius: 1rem;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 23.125rem;
    margin: 2rem 4rem;

    img {
      height: 20rem; // 320px
    }
  }

  h2, p {
    margin-top: 1.25rem;
  }
}