.app__project {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__project-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  margin: 4rem 0 2rem;

  .app__project-filter-item {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.175s ease-in-out;
    margin: 0.5rem;

    &:hover {
      background-color: var(--secondary-color);
      color: #fff;
    }

    @media screen and (min-width: 2000px) {
      padding: 1rem 2rem;
      border-radius: 0.85rem;
    }
  }

  .item-active {
    background-color: var(--secondary-color);
    color: #fff;
  }
}

.app__project-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .app__project-item {
    width: 19rem; // 270px
    min-height: 26.25rem; // 420px
    flex-direction: column;
    justify-content: flex-start;
    margin: 2rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;

    cursor: pointer;
    transition: all 0.175s ease-in-out;

    &:hover {
      box-shadow: 0 0 2.5rem rgba(0, 0, 0, .2);
    }

    @media screen and (min-width: 2000px) {
      width: 29.375rem; // 470px
      padding: 1.25rem;
      border-radius: 0.75rem;
    }

    @media screen and (max-width: 300px) {
      width: 100%;
      margin: 1rem;
    }
  }
}

.app__project-img {
  width: 100%;
  height: 14.375rem; // 230px
  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;

  }

  @media screen and (min-width: 2000px) {
    height: 21.875rem; // 350px
  }
}

.app__project-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  
  border-radius: 0.5rem;
  opacity: 0;
  transition: all 0.2s ease;

  div {
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .5);
    color: #fff;

    margin: 1rem;
    font-family: var(--font-base);
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
      width: 55%;
      height: 55%;
      color: var(--white-color)
    }
  }
}

.app__project-content {
  padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;

  h4 {
    margin-top: 1rem;
    line-height: 1.25rem;
  }

  .app__project-tag {
    position: absolute;

    padding: .5rem 1rem;
    border-radius: .625rem;
    background-color: #fff;
    top: -1.5rem;
  }

  @media screen and (min-width: 2000px) {
    padding-top: 3.5rem;
  }
}